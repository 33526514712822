
import { defineComponent } from "vue";
import { faFile  } from "@fortawesome/free-solid-svg-icons";

export default defineComponent({
  name: "APIList" ,
  data (){
      
      return {
          faFile : faFile ,
          apiList : [
          ] 
      
      }
  },
   mounted : async function (this:any){
       
    this.$emit('show-header');  
    this.$emit('show-loader',true);   
      
    const res  = await fetch( process.env.VUE_APP_APP_API_V4_URL  + "customapi/" ) ;
    const data = await res.json();
          
    while(this.apiList.pop()); 
        
    var api =data.apiList.pop();  

    while ( api ){

      this.apiList.push(api);
      api =data.apiList.pop(); 
    }
        
    this.$emit('show-loader',false);   
    
  },
  methods : {}
});
